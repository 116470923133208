import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/en/analytics-consulting",
  "Data Layer": "/en/data-layer"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Layer explained with Tutorial [2020]"
        description="A data layer is the fundamental concept for any professional web analytics setup. But what is it actually and how do we implement it?"
        lang="en"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        canonical="/en/data-layer"
        datePublished="2020-09-05T04:32:43.188Z"
        dateModified="2023-11-15T15:21:48.881Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualization of the Google Tag Manager Data Layer in the browser console"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Layer</H>
        <p>
          In connection to tag management and web analytics, you may have heard the term <b>data layer</b>. It is the
          fundamental element in an ambitious web analytics setup because all data points and tracking rules depend on
          it.
        </p>
        <p>
          That's why in the digital analytics space it is treated like an nonnegotiable requirement for any analytics
          setup. However, scenarios in which it is not necessary, do exist as well.
        </p>
        <p>
          Therefore, I want to explain <Link to="/en/data-layer#what-is-a-data-layer">what a data layer is</Link>,{" "}
          its <Link to="/en/data-layer#data-layer-benefits">benefits</Link>, and the differences between the{" "}
          <Link to="/en/data-layer#data-layer-for-google-tag-manager">data layer for Google Tag Manager</Link> and{" "}
          <Link to="/en/data-layer#data-layer-for-adobe-analytics">Adobe Launch</Link>.
        </p>
        <p>
          Afterwards, we will look at the implementation for the most popular Tag Management Systems (TMS). I will
          explain the <Link to="/en/data-layer#1">design</Link> phase, followed by the{" "}
          <Link to="/en/data-layer#2">implementation</Link> and <Link to="/en/data-layer#3">debugging</Link>.{" "}
        </p>
        <H as="h2">What is a data layer?</H>
        <p>A Data Layer is a <b>data structure</b> that provides relevant information in Key-Value Pairs for use with, for example, <Link to="/en/tag-management">Tag Management Systems</Link>.</p>
        <p>A Data Layer is available in the global JavaScript Scope of the website as an <b>Array</b> or <b>Object</b> and holds data in a structured form for other programs to use.</p>
        <p>The benefit of a Data Layer lies in a programmatically simple access to relevant data during a website visit.</p>

        <p>
          It enables access to data at one central point and is the basis for the data analytics logic in a <Link to="/en/tag-management#what-is-a-tag-management-system">tag
            management system</Link>.
        </p>
        <SnippetJS caption="Code snippet to create a datalayer for Google Tag Manager. It creates a JavaScript-Object in an array (!) holding all relevant dimensions for later data analysis and rule creation in the tag management system.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];`}
        </SnippetJS>
        <p>
          Since it is used to store data from multiple data sources, it simplifies monitoring of current data values,
          because only a single place has to be observed ("single point of truth").
        </p>
        <p>
          A data layer is <b>rebuilt on every page load</b> equipped with data points of the current web page and
          possibly other related data about the visitor and its visit.
        </p>
        <p>
          <b>Note:</b> Single page applications (SPA) do not reload the page in between the page navigation. That's
          why the data layer configuration for an single page application is different than for typical web pages with
          page loads.
        </p>
        <p>
          The held data represents characteristics or features of a subpage and is held in a key-value-pair. The keys
          hold descriptive names of the features paired with a current value, which typically changes during the user
          journey.
        </p>
        <SnippetJS caption="The relevant features of a subpage, such as its category, name and language are stored in variables and can later be leveraged for analysis or rule building in the TMS.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page", //category
  "pageName": "sneaker overview", //name
  "language": "en-US", //language
}];`}
        </SnippetJS>
        <p>
          The overall goal is to make those data points available in the tag management system, so they can be sent
          along with the data shared with e.g. Google Analytics or Facebook Ads to better describe website
          interactions.
        </p>
        <p>
          To enable this integration, the TMS holds references to the key-value-pairs and can for example execute
          rules once their value changes.
        </p>
        <p>
          <b>Example:</b> A visitor zooms into a product image and thereby triggers an event "product zoom". Without
          additional data sent together with the event, it is not very insightful. That's why we also send data about
          e.g. the product name, category and price, so we can analyze the event in a meaningful context.
        </p>
        <p>
          The additional data would make it possible to verify if such product zooms only occur in certain product
          categories. If so, it may be beneficial to add more images to other products in the same category as well,
          since visitors seem to be very interested in the image details of those products.
        </p>
        <p>
          The bottom line is, that we structure all relevant data in descriptive keys and values and make those
          available at a central place where it can be easily picked up.
        </p>
        <p>
          Such data points are usually important characteristics of the page content or some classification we came up
          with to segment visitors based on behavior.
        </p>
        <p>
          To better understand what a data layer is, as a simplified visualization, you can imagine an Excel sheet.
          The sheet holds important characteristics about a web page in its header (path, language, category,
          logged-in status) together with a current value for each item.
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Simplified data layer example in Excel"
            className="article-img"
            caption="You can imagine a data layer as a simple table with the column headlines "
          />
        </ImgContainerFixed>
        <H as="h2">What are the benefits of using a data layer?</H>
        <p>
          While a visitor navigates through a website many user interactions take place: clicks on buttons, forms that
          are filled out or watched videos.
        </p>
        <p>
          If those interactions allow us to draw conclusions about the user engagement they are sent to e.g. Google
          Analytics together with other descriptive data about the visitor, session, the event itself or the
          HTML-Element they interacted with.
        </p>
        <p>
          <u>And this is the crucial point</u>: The additional data to describe such interactions comes from different
          data sources, for example from the <b>frontend, database</b> or an external <b>API</b>.
        </p>
        <p>
          To understand the benefits of a data layer, we first have to understand the challenges that come up when we
          require data from multiple data sources.
        </p>
        <p>Let's look at an example where we're collecting data from such sources and let's think it through:</p>
        <p>
          <b>Example:</b> The visitor buys a product on a website. The following dimensions could be of interest:
        </p>
        <ul
        // TODO: show list in 2 columns, problem: looks good on desktop but not on mobile
        // style={{ columns: "2", "-webkit-columns": "2", "-moz-columns": "2" }}
        >
          <li>product name</li>
          <li>product price</li>
          <li>product size</li>
          <li>product category</li>
          <li>product color</li>
          <li>shopping basket value</li>
          <li>brand</li>
          <li>initial purchase</li>
          <li>customer segment</li>
          <li>customer discount</li>
          <li>gender</li>
          <li>country</li>
        </ul>
        <p>
          After the purchase visitors land on a thank-you page listing all details of the purchase and delivery
          address.
        </p>
        <p>
          <b>Frontend</b>: To send the product data, shopping basket value and brand along with the event, we can
          potentially scrape it from the thank-you page.
        </p>
        <p>
          The main challenge of scraping data from a webpage is that the data has to be available on each page where
          the interaction takes place. This is hardly the case in reality.
        </p>
        <p>
          It is advisable to measure as many of the same dimensions across all interactions of a website to make the
          interactions comparable later during data analysis. Hence, if we were to follow that approach, it is likely
          that other pages don't list <i>product data, shopping basket value and brand name</i> to send along with
          other events.
        </p>
        <p>
          So if the needed data is not available on other pages, we want to avoid adding all that data to the content
          just for the sake of analytics. <b>That's why we use a data layer</b>. It makes the data available for us to
          collect, no matter if it's visible on the page or not. It is literally a layer of data sitting on top of any
          given subpage, serving the data we need.
        </p>
        <p>
          Another challenge of scraping data from the frontend is that it eventually breaks. When pages are changed
          and those changes affect the HTML structure of the scraped elements, then the data collection will break.
          Especially in bigger companies, there are frequent changes on pages and multiple teams working on them
          without knowing if some HTML elements are needed for data collection. Hence, any scraping of data from the
          frontend will break at some point on frequently updated websites.
        </p>
        <p>
          A data layer leverages this approach and makes it possible to retrieve user data in a secure and yet simple
          manner.
        </p>
        <p>
          <b>Database</b>: Collecting the customer data (customer segment, discount, gender and initial purchase) can
          get a bit hairy: Customer data would either have to be sent along with the server response or with a
          separate API.
        </p>
        <p>
          But since this is private data, the collection has to be authenticated for data protection purposes. That
          means an API request couldn't be handled in the browser because the API key would otherwise be retrievable
          for experienced users.
        </p>
        <p>
          Therefore, the best solution is to send the data together with the server response based on the websites'
          login authentication. <br />
          When the user is logged in, the data layer is populated with the relevant data from the database. Without
          login, no sensitive data gets exposed.
        </p>
        <p>
          <b>API</b>: Geo data such as the country could be retrieved from an external service API.
        </p>
        <p>
          However, the same challenge as when retrieving data from the database arises: Any API requests from the
          frontend or the tag management system require an API key, which shouldn't be handled in the browser for
          security reasons.
        </p>
        <p>
          Another disadvantage of working with APIs for data collection especially with events is the duration until
          the data comes back. If a user navigates to a different page before the data arrived, we risk losing the
          event.
        </p>
        <p>Let's summarize the benefits quickly:</p>
        <H as="h3">Benefits</H>
        <ul>
          <li>Data is available no matter if it is visible on the page</li>
          <li>Robust data collection</li>
          <li>Secure collection of sensitive data</li>
          <li>Mitigating data loss for asynchronous data requests</li>
        </ul>
        <H as="h2">Why you probably need one</H>
        <p>
          By creating a data layer a JavaScript-Object is made available in the global scope of the browser on each
          page load.
        </p>
        <p>
          The data it holds can derive from your database, frontend or APIs, so data collection from those sources
          becomes reliable, secure and independent of the HTML on the page.
        </p>
        <p>
          Data from the database can be made available on any subpage of the site without much hassle without being
          visible in the content.
        </p>
        <p>
          For the above reasons I generally advise clients to implement data layers if they are serious about their
          data analysis ambitions. The advantages of data quality, reliability and the related long-term time savings
          justify the higher implementation efforts.
        </p>
        <p>
          The ultimate goal of web analytics is to make data-driven business decisions, so data quality should be a
          priority.
        </p>
        <p>
          Now, let's look at the different options available and some implementation examples before we dive into the
          design and implementation phase.
        </p>
        <H as="h2">Data Layer for Adobe Analytics, DTM, Launch and Tealium</H>
        <p>
          Data Layers can have different structures. Generally, we distinguish between the ones that come with an{" "}
          <b>object-based structure</b> and an <b>array-based structure</b>.
        </p>
        <p>
          According to the{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            data layer definition
          </a>{" "}
          by the World Wide Web Consortium (W3C) the syntax follows the one of a JavaScript object. It is unofficially
          shortened <b>CEDDL</b> (Customer Experience Digital Data Layer).
        </p>
        <p>You can also nest other objects or arrays in it as well:</p>
        <SnippetJS caption="Example of a data layer for Adobe Analytics with an object-based structure, following the W3C definition.">
          {`
window.digitalData = {
  pageName: "sneaker overview",
  destinationPath: "/en/sneakers",
  breadCrumbs: ["home","sneakers"],
  publishDate: "2020-07-01",
  language: "en-US"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> and Tealium follow the CEDDL structure. In the above example, we store
          data in an object called <code>digitalData</code>. The name is not standardized and can be chosen freely,
          but you have to declare the name in the tag management system.
        </p>
        <p>
          To change the data, there are multiple options (as with any JS object) however, the simplest way is to just
          overwrite the values:
        </p>
        <SnippetJS caption="To add data to the data layer, simply overwrite existing data as with any regular JavaScript object.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          The central idea of the object-based structure is that they are once loaded per page load, but they are not
          altered much based on user interaction. The data is mostly <b>static</b>.
        </p>
        <p>
          Event tracking is not driven by events that enter the data layer object. Events are tracked with a{" "}
          <b>separate tracking library</b> to send them further to an analytics platform e.g. Adobe Analytics. When
          the event tracking code is executed, the data layer object is sent along in its entirety and can be used
          during data analysis.
        </p>
        <SnippetJS caption="Event tracking for Adobe with an object-based data layer structure is handled through the _satellite library.">
          {`
//Event with chosen color
_satellite.setVar("sneaker color", "black");
_satellite.track("select color"); 

`}
        </SnippetJS>
        <H as="h2">Use Adobe Launch with an array-based data layer</H>
        <p>
          You can easily use Adobe Launch with an array-based structure too. The Adobe Launch Extension{" "}
          <b>Data Layer Manager</b> makes it possible.
        </p>
        <p>Here are some links to some further resources for using the array-based version with Adobe Launch:</p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo of using Data Layer Manager with Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension with{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              documentation
            </a>
          </li>
        </ul>
        <H as="h2">Data Layer for Google Tag Manager, Matomo and Piwik Pro</H>
        <p>
          The <b>data layer for Google Tag Manager</b>, Matomo and Piwik Pro is array-based and unofficially referred to as
          the event-driven data layer <b>(EDDL)</b>.
        </p>
        <p>
          Data is handled in objects as well, but the overall structure of the GTM data layer is an <b>array with objects</b>.
        </p>
        <SnippetJS caption="Code snippet for adding a GTM data layer">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];
`}
        </SnippetJS>
        <p>
          The tracking-logic with an array-based structure is different: New data or changes are pushed into it via{" "}
          <code>dataLayer.push()</code>. So a <b>push to the data layer</b> can then trigger tag executions in the tag management system.
        </p>
        <p>
          The fundamental difference to an object-based structure is that changes are usually sent together with an
          event and that rules are triggered based on those changes without any additional library, just by observing
          if the data layer array changes.
          <br />
          Since no other library like <code>_satellite</code> is necessary we require one dependency less.
        </p>
        <p>
          Another characteristic of the array-based approach is that the data changes rather frequently throughout the
          user journey since any user interaction may alter the data layer variables.
        </p>{" "}
        <p>
          So an array-based data layer is the basis for event tracking and handles data more flexibly whereas an
          object-based one rather serves as a static data store.
        </p>
        <p>
          Through that flexibility, an array-based data layer structure is said to be more suitable for
          Single-Page-Applications. <br />
          However, you can also track SPAs with an object-based data layer, it will just require a few more lines of
          code and potentially a few edge cases to solve.
        </p>
        <p>
          If you are at the beginning of a project and have the choice, I would prefer an array-based data layer
          however.
          <br />
          Changing an already existing setup from an object structure to an array, however, is unnecessary.
        </p>
        <H as="h2">Content Management Systems with data layer included</H>
        <p>
          <b>WordPress</b> users have it easy since they can use{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            this plugin
          </a>{" "}
          to implement Google Tag Manager together with a preconfigured data layer.
        </p>
        <p>It automatically populates with categories, author names, publish dates and search terms.</p>
        <p>
          The data points can be checked or unchecked in the plugin settings. Furthermore, the plugin offers
          pre-configured events for form submissions of the most common form plugins.
        </p>
        <p>
          If you're a webshop owner and use <b>WooCommerce</b> for WordPress, you can implement a{" "}
          <b>classic e-commerce data layer</b> as well as an <b>enhanced e-commerce data layer</b> with the same
          plugin, which is pretty mighty.
        </p>
        <p>
          <b>WordPress</b> users that want to use <b>Tealium</b> can use a plugin for{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Tealium
          </a>
          .
        </p>
        <p>
          <b>Drupal</b> also has a{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            plugin
          </a>
          .
        </p>
        <p>
          <b>Wix</b> and <b>Squarespace</b> users can implement Google Tag Manager through the platform-tools but have
          to implement the data layer code manually.
        </p>
        <H as="h2">Data layer implementation</H>
        <p>
          So how do you implement a data layer? - Since planning and implementation require knowledge across the areas
          of digital analytics, frontend development and backend development, the implementation is usually carried
          out through a web agency together with an{" "}
          <Link to="/de/google-analytics-consultant">analytics consultant</Link>.
        </p>
        <p>
          The analytics consultant briefs the web agency and leads the project until the implementation is
          successfully validated. Afterwards the tag management system and analytics tools are configured.
        </p>
        <p>
          If you are interested and know a bit of JavaScript you can implement it yourself with the following
          <b>implementation guide</b>.
        </p>
        <p>The implementation goes through 3 steps:</p>
        <ol>
          <li>
            <Link to="/en/data-layer#1">Data Layer Design</Link>
          </li>

          <li>
            <Link to="/en/data-layer#2">Implementation</Link>
          </li>

          <li>
            <Link to="/en/data-layer#3">Debugging</Link>
          </li>
        </ol>
        <H as="h3">1. Data Layer Design</H>
        <p>
          The design phase is all about defining what interactions should be measured together with what dimensions.
        </p>
        <p>
          Any attributes of the visitor, session, page, product or event can be of potential interest during data
          analysis and should be considered for the data layer architecture.
        </p>
        <p>
          To decide what to include, start with the end in mind and ask yourself which business-critical questions
          need to be addressed and narrow down on the related data points.
        </p>
        <p>
          The next step is to figure out how those data points must be structured and which dimensions are the most
          important to add.
        </p>
        <p>
          <b>Example</b>: A language school running a WordPress website in multiple languages wants to know the native
          language of their website visitors and which foreign language they are most interested in. The goal is to
          potentially run online advertising via Facebook Ads targeting demographics with similar attributes.
        </p>
        <p>
          As a next step, we would need to define all relevant data across different types of pages (homepage, course
          pages, about us, contact and news). To simplify, let's look at the most interesting pages and focus on the
          front page and course pages.
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-example-language-school.png"
            alt="Data Layeer design example for a language school website"
            caption="Example of a data layer design for a Language School front page and course pages"
          />
        </ImgContainerFixed>
        <p>
          <b>Example</b>: Array-based Google Tag Manager data layer for a language school
        </p>
        <SnippetJS caption="Code snippet to initiate a GTM data layer for a language school example. Note that numbers are declared as strings.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": "de", //Language of UI
  "sessionDuration": "182", //Session duration in sec
  "languageIntent": "es", //most-visited course language
  "pageType": "course page",
  "courseName": "Spanish A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Duration in weeks
}];
`}
        </SnippetJS>
        <p>
          <b>Example</b>: Object-based data layer for Adobe Launch
        </p>
        <SnippetJS caption="Initialization of a data layer for Adobe Launch or Adobe DTM.">
          {`
window.digitalData = window.digitalData || {
  "language": "de", //Language of UI
  "sessionDuration": 182, //Session duration in sec
  "languageIntent": "es", //most-visited course language
  "pageType": "course page",
  "courseName": "Spanish A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Duration in weeks
};
`}
        </SnippetJS>
        <H as="h3">2. Implementation</H>
        <p>
          Data layers have to be implemented on every subpage of a website. The above code examples, however show the
          final <b>computed state</b>.
        </p>
        <p>
          During implementation the data points first need to be sourced to compute into their final state, so the
          actual source is going to look slightly different.
        </p>
        <p>To give a realistic example however, I assume the following:</p>
        <ul>
          <li>
            Session duration and language interest are collected through a custom-made JavaScript and held in the
            browsers local storage.
          </li>
          <li>
            Language, page type and course data can be retrieved from the database via the server response and made
            available on the course templates and front page.
          </li>
        </ul>
        <p>The data layer source code in the backend according to the above premises would look like this:</p>
        <SnippetJS caption="Example source code for a data layer before computation.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
        </SnippetJS>
        <H as="h4">Event tracking with data layer push</H>
        <p>To send events to a GTM data layer you can use its push-method and literally push events into it.</p>
        <SnippetJS caption="Data layer push example for event tracking">
          {`
window.dataLayer.push({
  "event": "course-booking", 
  "startWeek": "24"
});
`}
        </SnippetJS>
        <p>
          The <b>event</b> keyword is a special keyword and can be addressed as a <b>custom event</b> from the GTM
          container.
        </p>
        <p>
          The tag management system observes the data layer and executes a tag as soon as a predefined custom event is
          sent to it.
        </p>
        <p>After an event is added the TMS can for example send an event to Google Analytics.</p>
        <p>
          All relevant data to provide context (name, language, language level, course duration) is available and can
          be sent along with the event, for example, the start week for the course.
        </p>
        <p>
          In an object-based data layer, the same event would be directly sent to adobe analytics via their own event
          tracking library.
        </p>
        <p>For Adobe Launch the example code would look like this:</p>
        <SnippetJS caption="Adobe Launch example for event tracking. Note that the data is sent directly to Adobe Analytics without going through the data layer first">
          {`
_satellite.setVar("startWeek", 24);
_satellite.track("course-booking");
`}
        </SnippetJS>
        <H as="h4">Code positioning in the source code</H>
        <p>
          The data layer code should be added in the <code>{`<head>`}</code> of the page before the tag management system.
        </p>
        <p>
          Due to this order, you ensure that it is already computed when the tag management system wants to access it.
        </p>
        <p>
          <b>Example</b>: Positioning in the source code
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positioning the data layer before the TMS tag in the source code"
          caption="The data layer should be positioned in the <head> section before the tag management system, to ensure its data is loaded already when the TMS wants to access it."
        />
        <H as="h3">3. Debugging</H>
        <p>
          The most common procedures to debug a data layer are simulating page loads or events to verify that all data
          points populate with the correct data.
        </p>
        <p>
          Since it is globally accessible in the browser console, you can simply print all values to the console
          (assuming that standard naming conventions are applied):
        </p>
        <p>
          <b>Websites with GTM</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Websites with Adobe Launch or DTM</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium</b>
        </p>
        <p>
          <code>utag.data</code> or <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager even comes with its own <strong>Debugger Mode</strong>. You can activate it from the GTM
          interface by clicking on <b>preview</b> in the top right.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-activate-debugger-mode.png"
          alt="Screenshot of Google Tag Manager showing the button to activate the debugging mode"
          caption={`You can activate GTM's debugger mode by clicking on the preview button. If you then visit your website with the installed GTM container, the debugger window will pop up on the bottom of the browser.`}
        />{" "}
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="screenshot of the GTM debugger window"
          caption="You can inspect all data layer variables and see their current values while you interact with the website. In addition, you can overlook the sequence of events that enter too. Click on each event to see which changes the event caused."
        />
        <p>
          In case you don't have access to the tag management container but want to debug it anyway, you can use a{" "}
          <b>chrome extension</b>.
        </p>
        <H as="h4">Data Layer Chrome Extensions</H>
        <p>
          There is a variety of potential extensions for debugging out there. I prefer the ones that support most
          vendors, so I don't have to switch between extensions when debugging another site.
        </p>
        <p>The following chrome extensions are currently my favorites for debugging:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Extension based on{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            and open source. It has a pretty good performance and supports GTM, DTM, Tealium{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              and many more
            </a>
            .
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - Another all-rounder with support for Adobe Analytics (DTM & Launch), as well as Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              and more
            </a>
            .
          </li>
        </ul>
        <H as="h4">Chrome Extensions for debugging Google Analytics and GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - after activating the extension there will be a new tab available in Chrome DevTools once you open them
            (F12 on Windows and CTRL+SHIFT+i on Mac). All relevant data is shown in a graphical UI and it is updated
            while you browse a website.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - logs all relevant data points into the browser console. Activate "preserve log" in the console settings,
            to keep logs across page navigation.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - definitively the extension with the coolest name and logo and my previous favorite. It is open source
            with a simple layout and also works with Adobe DTM. <br />
            <u>
              Uncheck “use three-column layout where available”, “show GA Classic tags” and “show Floodlight tags” in
              the extension settings,
            </u>{" "}
            otherwise the logs get a bit messy.
          </li>
        </ul>
        <H as="h4">Chrome Extensions for debugging Adobe Analytics, Launch and DTM</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - allows you to load the staging or production library of the tag management system and can activate the
            debug mode.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger for Adobe Analytics</b>
            </a>{" "}
            - activates the debug mode. Alternatively, you can also type <code>_satellite.setDebug(true)</code> into
            the console.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - meta extension to debug all adobe products.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - successor of the experience cloud debugger that offers a better overview (currently still in beta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - simple tables of all current values.
          </li>
        </ul>
        <H as="h2">E-Commerce Data Layer</H>
        <p>
          Data layers for e-commerce are more extensive and their structure is more complex. They have to hold more
          data and manage more events.
        </p>
        <p>That's why the planning and implementation of an e-commerce website take considerably more time.</p>
        <p>
          The e-commerce report in Google Analytics, for example, does not show any data if the implementation does
          not follow their{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=de">
            e-commerce data layer documentation
          </a>
          .
        </p>
        <p>
          Large e-commerce stores however require even more advanced tracking setups. They implement a{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            data layer for enhanced e-commerce
          </a>
          , which enables even more functionality in Google Analytics.
        </p>
        <p>
          You need to follow the implementation guidelines for Google Analytics strictly to make the e-commerce
          reports work. That concerns the data layer structure and also variable names.
        </p>
        <p>
          If you choose another analytics platform for e-commerce, you are free to plan the structure as you like.
        </p>
        <H as="h2">When is a data layer not necessary?</H>
        <p>
          As with anything, there are also cases where the additional effort of implementing a data layer is not
          justified.
        </p>
        <p>
          In the above examples, we looked at cases where we pulled data from different data sources (Frontend,
          Backend, API) and solved problems that came up when working with a variety of data sources.
        </p>
        <p>
          Many websites (so-called brochure-websites) however don't even have a login functionality nor do they have a
          database.
        </p>
        <p>
          Another important factor is how often changes are implemented on the website. Many sites seldomly review
          their content or add functionality on a regular basis. I even see enterprises run simple brochure websites
          with about 50 subpages and a contact-form as the hardest conversion.
        </p>
        <p>
          Since such sites most likely only require data from the frontend to do their data analysis, they could do
          with a simple analytics setup without any data layer. It wouldn't make the collection part much more robust
          or more secure, hence its advantages are mitigated. Under such circumstances, the benefits don't justify the
          additional effort of implementation.
        </p>
        <p>
          Typical examples of when a data layer is not necessary, are brochure sites or content websites with a
          limited number or almost no hard-conversions. Usually, such site owners are simply interested in
          categorizing the user engagement by their content sections or some company internal classifications.
        </p>
        <p>
          Such requirements can be achieved with some advanced JavaScript and a thought-through system for structuring
          the content.
        </p>
        <p>
          As soon as data collection from the frontend regularly breaks and definitely when a database should be
          involved, a data layer is recommended.
        </p>
        <p>
          Alternative solutions are often just temporarily satisfying, due to increasingly growing analytics ambitions
          and regularly breaking data collection. Also, any custom solutions are usually difficult to pass on to
          another agency.
        </p>
        <p>
          A data layer has good chances of surviving the test of time because it already is an established concept in
          the web analytics sector, so web agencies have increasing experience implementing and maintaining one.
        </p>
        <H as="h2">Conclusion</H>
        <p>
          A data layer is the gold-standard for professional analytics setups. It increases data quality and thereby
          improves data analysis as a whole, while fulfilling security requirements.
        </p>
        <p>
          For ambitious website owners that want to begin with serious data analysis, it is the easiest and most
          robust solution.
        </p>
        <p>
          If you have the choice, implement an array-based structure, since you have fewer dependencies and can use it
          on all kinds of websites.
        </p>
        <p>
          Content websites however are so limited in functionality and usually only bring a limited amount of hard
          conversions, so a data layer can potentially be neglected. This holds true especially if all needed data is
          available on the pages or could be made available through some detours.
        </p>
        <p>
          If you want to implement one yourself doing it with a WordPress website is probably the easiest. Any
          advanced requirements however are probably not worth spending the time and risking an unsatisfactory result.
          <br />
          Therefore, implementing with the help of an analytics consultant is usually the way to go, since it saves
          time and avoids unnecessary risks.
        </p>
        <p>
          I recommend you to install one of the mentioned chrome extensions to inspect data layers of some bigger
          websites out there. It is usually a great inspiration and yields some interesting KPIs to potentially
          integrate into your own analytics setups 😉.
        </p>
        <H as="h2">Data Layer documentation of various TMS vendors</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Initiation and changing data
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Initiation
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Initiation
            </a>{" "}
            and{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              changing data
            </a>
          </li>

          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Initiation
            </a>{" "}
            and{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              changing data
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Initiation
            </a>{" "}
            and{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              changing data
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is a data layer example?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A data layer example is provided in the article. A JavaScript object stores data from a website, database, or an external source in a central, flexible, and easily accessible manner. An example code snippet for initiating a data layer for Google Tag Manager is: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"category page\", \"pageName\": \"sneaker overview\", \"language\": \"en-US\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "What are data layer variables?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data layer variables are key-value pairs within the data layer that store specific pieces of information. These variables can include page characteristics, user behavior data, and more, serving as a central data repository for analytics and tracking."
              }
            },
            {
              "@type": "Question",
              "name": "Why use a data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A data layer is essential for robust, flexible, and secure data collection. It centralizes data from various sources, making it easily accessible and consistent across different web pages and user interactions. This approach enhances data quality and reliability, which is crucial for data-driven decision-making."
              }
            },
            {
              "@type": "Question",
              "name": "Do I need a data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "While not always necessary, a data layer is generally advised for those serious about their data analysis ambitions. It provides data quality, reliability, and long-term time savings that justify the higher implementation efforts."
              }
            },
            {
              "@type": "Question",
              "name": "What are the advantages of a data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The advantages of a data layer include: Availability of data regardless of its visibility on the page. Robust data collection. Mitigation of data loss for asynchronous data requests. Secure data collection from multiple sources."
              }
            },
            {
              "@type": "Question",
              "name": "Do all websites have a data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Not all websites have a data layer. Its implementation depends on the complexity of the website and the depth of data analysis required. Simple websites may not have a data layer, while more complex sites, especially those focused on data-driven decision-making, likely will."
              }
            },
            {
              "@type": "Question",
              "name": "How do I access the data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The data layer is globally accessible in the browser console. For websites with Google Tag Manager, you can access it using dataLayer or Object.assign(...dataLayer). For Adobe Launch or DTM, you can access it using digitalData."
              }
            },
            {
              "@type": "Question",
              "name": "How do you push to the data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "To push to the data layer, you use the dataLayer.push() method. For example: window.dataLayer.push({ \"event\": \"course-booking\", \"startWeek\": \"24\" }); This method is used to add new data or changes to the data layer. The event-key can be used to trigger another tag execution in the tag management system."
              }
            }
          ]
        }
        } />
        <H as="h2">Further Resources</H>
        <ul>
          <li>
            Simo Ahava about the{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              data layer in GTM
            </a>{" "}
            and{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              how to handle data in it.
            </a>
          </li>
          <li>Read my <Link to="/en/google-tag-manager-setup">Google Tag Manager tutorial</Link> and learn setting it up.</li>
          <li>
            Kevin Haag’s presentation from Measurecamp Berlin 2019 about the{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer in Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default DataLayer;

// data layer suggest terms
// https://docs.google.com/spreadsheets/d/1jlPtVYDs49jP3gDQBCEmSck-gCj304Nv2U2md2rUvs4/edit?usp=sharing

// FAQs
// How can you rename a data layer?
// What are data layer variables?
// What is an 'event' data layer variable?
// How can you initialize a data layer?
// Why use a data layer? (also covers "Why data layers?")
// What is an example of a data layer?
// What is data layer in Google Tag Manager?
// How to check data layer in console?
// How to view data layer in Chrome?
// How to create a data layer?